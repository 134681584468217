var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var ImportOthersDataExcelRecordService = /** @class */ (function (_super) {
    __extends(ImportOthersDataExcelRecordService, _super);
    function ImportOthersDataExcelRecordService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 取消导入
     * @param id
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.cancelOthersExcelImpCase = function (id, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.cancelOthersExcelImpCase,
            append: [id],
            loading: loading,
        });
    };
    /**
     * 确认导入
     * @param id
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.confirmOthersExcelImpCase = function (id, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.confirmOthersExcelImpCase,
            append: [id],
            loading: loading,
        });
    };
    /**
     * 查询导入结果
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.getImportOthersDataExcelList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportOthersDataExcelList,
            data: {
                principalName: data.principalName,
                batchNumber: data.batchNumber,
                importDataExcelStatus: data.importDataExcelStatus,
                templateType: data.templateType,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 其他数据导入
     * @param data
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.importOthersExcelData = function (data, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.importOthersExcelData,
            data: {
                fileId: data.fileId,
                principalId: data.principalId,
                principalName: data.principalName,
                templateId: data.templateId,
                templateType: data.templateType,
                casePool: data.casePool,
            },
            loading: loading,
        });
    };
    /**
     * 案件对账导入详情
     * @param data 查询参数
     * @param page 分页
     * @param sort 排序
     * @param loading 加载等待框
     */
    ImportOthersDataExcelRecordService.prototype.getImportBillDataList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportBillDataList,
            data: {
                operBatchNumber: data.operBatchNumber,
                bachNumber: data.bachNumber,
                caseNumber: data.caseNumber,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 城市导入详情
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.getImportChangeCityList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportChangeCityList,
            data: {
                operBatchNumber: data.operBatchNumber,
                bachNumber: data.bachNumber,
                caseNumber: data.caseNumber,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 案件停调导入详情
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.getImportEndList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportEndList,
            data: {
                operBatchNumber: data.operBatchNumber,
                bachNumber: data.bachNumber,
                caseNumber: data.caseNumber,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 案件留案导入详情
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.getImportLeafList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportLeafList,
            data: {
                operBatchNumber: data.operBatchNumber,
                bachNumber: data.bachNumber,
                caseNumber: data.caseNumber,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 委前调记导入详情
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.getImportPreRecordList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportPreRecordList,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 案件更新导入详情
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.getImportUpdateDataList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportUpdateDataList,
            data: {
                operBatchNumber: data.operBatchNumber,
                bachNumber: data.bachNumber,
                caseNumber: data.caseNumber,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 案件警告信息导入详情
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.getImportWarnList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportWarnList,
            data: {
                operBatchNumber: data.operBatchNumber,
                bachNumber: data.bachNumber,
                caseNumber: data.caseNumber,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 案件工单信息导入详情
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.getImportWorkOrderList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportWorkOrderList,
            data: {
                operBatchNumber: data.operBatchNumber,
                bachNumber: data.bachNumber,
                caseNumber: data.caseNumber,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 案件工单信息导入详情
     * @param data 查询参数
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    ImportOthersDataExcelRecordService.prototype.getImportCaseAnnexList = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getImportCaseAnnexList,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 对账撤销
     */
    ImportOthersDataExcelRecordService.prototype.billRevoke = function (id) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.billRevoke,
            data: {
                id: id,
            },
        });
    };
    /**
     * 查询批次号
     */
    ImportOthersDataExcelRecordService.prototype.getBatchNumberByPrincipalId = function (principal) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.getBatchNumberByPrincipalId,
            data: {
                principal: principal,
            },
        });
    };
    /**
     * 对账撤销(单个记录)
     */
    ImportOthersDataExcelRecordService.prototype.revokeOneBill = function (id) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.revokeOneBill,
            data: {
                id: id,
            },
        });
    };
    /**
     * 退案
     */
    ImportOthersDataExcelRecordService.prototype.importPauseCase = function (data) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.importPauseCase,
            data: {
                fileId: data.fileId,
                principalId: data.principalId,
            },
        });
    };
    /**
     * 撤销
     */
    ImportOthersDataExcelRecordService.prototype.revokeOthersExcelImp = function (id) {
        return this.netService.send({
            server: dataimpService.importOthersDataExcelRecordController.revokeOthersExcelImp,
            data: {
                id: id,
            },
        });
    };
    __decorate([
        Inject(NetService)
    ], ImportOthersDataExcelRecordService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ImportOthersDataExcelRecordService.prototype, "cancelOthersExcelImpCase", null);
    __decorate([
        Debounce()
    ], ImportOthersDataExcelRecordService.prototype, "confirmOthersExcelImpCase", null);
    return ImportOthersDataExcelRecordService;
}(Service));
export { ImportOthersDataExcelRecordService };
