var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import DepartmentTree from "~/components/system-manage/department-tree.vue";
import AdjustmentBatchAllocate from "~/components/case-info/case-allot/adjustment-batch-allocate.vue";
import { Prop, Emit } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
// import { UserService } from "~/services/business-service/user.service";
import { OrganizationService } from "~/services/management-service/organization.service";
import { CaseDistributeService } from "~/services/dataimp-service/case-distribute.service";
import { DistributeCaseService } from "~/services/domain-service/distribute-case.service";
var inrushCaseModule = namespace("inrush-case");
var AdjustmentCaseBatchAllot = /** @class */ (function (_super) {
    __extends(AdjustmentCaseBatchAllot, _super);
    function AdjustmentCaseBatchAllot() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = false;
        _this.batchNumberList = [];
        _this.cityList = [];
        _this.departList = [];
        _this.collectorList = [];
        _this.selectionList = [];
        _this.userDataSet = [];
        _this.distributeConfigModel = {};
        _this.userModel = {
            organizationId: "",
            userName: "",
            fullName: "",
            isShowAll: "0",
            operatorType: "COLLECTOR",
        };
        _this.totalCases = 0;
        _this.totalAmt = 0;
        _this.model = {};
        _this.distributeWay = "MANUAL_WAY";
        _this.batchNumberModel = {
            principalId: "",
            batchNumber: "",
            cities: [],
            collectors: [],
            departs: []
        };
        _this.batchNumberRules = {
            principalId: [{ required: true, message: "请选择委托方", trigger: "blur" }],
            batchNumber: [{ required: true, message: "请选择批次号", trigger: "blur" }]
        };
        _this.dialog = {
            personalManualDivisionNext: false
        };
        return _this;
    }
    Object.defineProperty(AdjustmentCaseBatchAllot.prototype, "totalPerson", {
        get: function () {
            return this.selectionList.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AdjustmentCaseBatchAllot.prototype, "personalDataSet", {
        get: function () {
            var items = [];
            return this.selectionList.map(function (item) {
                return Object.assign({ limitNum: 0, limitAmt: 0 }, item);
            });
        },
        enumerable: false,
        configurable: true
    });
    AdjustmentCaseBatchAllot.prototype.onclose = function () {
        var databox = this.$refs["data-box"];
        databox.clearSelection();
        var form = this.$refs["form"];
        if (form) {
            form.resetFields();
        }
        this.totalCases = 0;
        this.totalAmt = 0;
        this.distributeWay = "MANUAL_WAY";
    };
    AdjustmentCaseBatchAllot.prototype.onSuccess = function () {
        this.onclose();
    };
    AdjustmentCaseBatchAllot.prototype.created = function () {
        this.userModel.organizationId = this.userData.organization;
    };
    AdjustmentCaseBatchAllot.prototype.mounted = function () {
    };
    /**
     * 下一步，进入分配确认阶段
     */
    AdjustmentCaseBatchAllot.prototype.nextStep = function () {
        var _this = this;
        if (this.selectionList.length == 0) {
            this.$message.info("请选择人员");
            return;
        }
        var distributeConfigModels = this.personalDataSet.map(function (x) {
            var distributeConfigModel = {
                // 分配人员ID
                userId: x.id,
                // 员工工号
                userName: x.username,
                // 全名
                fullName: x.fullName,
                // 机构Id
                detaptId: x.organization,
                // 机构名称
                detaptName: x.detaptName,
                // 分配数量上限
                limitNum: x.limitNum,
                // 分配金额上限
                limitAmt: x.limitAmt,
                // 分配数量
                caseNumTotal: 0,
                // 分配金额
                leftAmtTotal: 0,
                // 百分比
                perNum: 0,
                // 分配的案件ID
                resultMap: []
            };
            return distributeConfigModel;
        });
        this.model = {
            // 案件总金额
            caseAmtTotal: this.totalAmt,
            // 调解员总数
            collectorTotal: this.selectionList.length,
            // 案件总数
            caseNumTotal: this.totalCases,
            // 分配规则
            distributeConfigModels: distributeConfigModels,
            // 分配方式
            distributeWay: this.distributeWay,
            // 批次号
            batchNumber: this.batchNumberModel.batchNumber,
            // 案件状态
            issuedFlag: this.caseIssuedFlag,
            // 城市数据
            citys: this.batchNumberModel.cities,
            // 案件所属部门
            departs: this.batchNumberModel.departs
        };
        this.caseDistributeService
            .groupCaseBatchRenew(this.model)
            .subscribe(function (data) {
            _this.distributeConfigModel = data;
            _this.dialog.personalManualDivisionNext = true;
        });
    };
    /**
     * 分配给调解员open
     */
    AdjustmentCaseBatchAllot.prototype.userOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var userAllocate = _this.$refs["user-allocate"];
        });
    };
    /**
     * 获取选中的节点
     */
    AdjustmentCaseBatchAllot.prototype.getCheckedNodes = function (node) {
        this.userModel.organizationId = node.id;
        this.refreshData();
    };
    AdjustmentCaseBatchAllot.prototype.refreshData = function () {
        var _this = this;
        this.organizationService
            .queryByOrganizationId(this.userModel, this.pageService, this.sortService)
            .subscribe(function (data) {
            _this.userDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    Object.defineProperty(AdjustmentCaseBatchAllot.prototype, "divisionIsEdited", {
        get: function () {
            return this.distributeWay == "MANUAL_WAY" ? false : true;
        },
        enumerable: false,
        configurable: true
    });
    AdjustmentCaseBatchAllot.prototype.divisionTypeChanged = function (data) {
        if (data !== 3) {
            this.personalDataSet.forEach(function (x) {
                x.maxCount = 0;
                x.maxAmt = 0;
            });
        }
    };
    /**
     * 委托方选中改变事件
     */
    AdjustmentCaseBatchAllot.prototype.principalChange = function (value) {
        var _this = this;
        var searchModel = {
            request: this.caseIssuedFlag,
            principalId: value
        };
        // 地区待分配批次号
        this.distributeCaseService
            .getBatchNumberByCaseIssuedFlag(searchModel)
            .subscribe(function (data) {
            (_this.batchNumberList = data);
        }, function (err) {
            _this.loading = false;
        });
    };
    AdjustmentCaseBatchAllot.prototype.cancel = function () {
        this.onclose();
    };
    /**
     * 批次号选中改变事件
     */
    AdjustmentCaseBatchAllot.prototype.batchNumberChange = function (value) {
        var _this = this;
        // 清空城市选中数据
        this.batchNumberModel.cities = [];
        // 清空城市选中数据
        this.batchNumberModel.departs = [];
        this.loading = true;
        // 地区待分配批次号
        this.distributeCaseService
            .getCaseCommonPro(value, this.caseIssuedFlag)
            .subscribe(function (data) {
            if (data) {
                // 城市数据
                _this.cityList = data.citys;
                _this.departList = data.departs;
                // 调解员
                _this.collectorList = data.collectors;
                // 案件所属部门
                var queryModel = {
                    // 批次号
                    batchNumber: value,
                    // 案件状态
                    issuedFlag: _this.caseIssuedFlag,
                    // 城市数据
                    citys: _this.batchNumberModel.cities,
                    // 调解员
                    collectors: _this.batchNumberModel.collectors,
                    // 案件所属部门
                    departs: _this.batchNumberModel.departs
                };
                _this.getBaseCaseNumberAndAmt(queryModel);
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    AdjustmentCaseBatchAllot.prototype.citiesChange = function (value) {
        this.loading = true;
        var queryModel = {
            // 批次号
            batchNumber: this.batchNumberModel.batchNumber,
            // 案件状态
            issuedFlag: this.caseIssuedFlag,
            // 城市数据
            citys: value,
            // 调解员
            collectors: this.batchNumberModel.collectors,
            // 案件所属部门
            departs: this.batchNumberModel.departs
        };
        this.getBaseCaseNumberAndAmt(queryModel);
    };
    AdjustmentCaseBatchAllot.prototype.departsChange = function (value) {
        this.loading = true;
        var queryModel = {
            // 批次号
            batchNumber: this.batchNumberModel.batchNumber,
            // 案件状态
            issuedFlag: this.caseIssuedFlag,
            // 城市数据
            citys: this.batchNumberModel.cities,
            // 调解员
            collectors: this.batchNumberModel.collectors,
            // 案件所属部门
            departs: value
        };
        this.getBaseCaseNumberAndAmt(queryModel);
    };
    AdjustmentCaseBatchAllot.prototype.getBaseCaseNumberAndAmt = function (queryModel) {
        var _this = this;
        this.distributeCaseService.getBaseCaseNumberAndAmt(queryModel).subscribe(function (data) {
            if (data) {
                // 案件数量[caseNumber]
                _this.totalCases = data.caseNumber;
                // 案件金额[caseAmt]
                _this.totalAmt = data.caseAmt;
            }
            _this.loading = false;
        }, function (error) {
            _this.loading = false;
        });
    };
    __decorate([
        Dependencies(SortService)
    ], AdjustmentCaseBatchAllot.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], AdjustmentCaseBatchAllot.prototype, "pageService", void 0);
    __decorate([
        Dependencies(OrganizationService)
    ], AdjustmentCaseBatchAllot.prototype, "organizationService", void 0);
    __decorate([
        Dependencies(CaseDistributeService)
    ], AdjustmentCaseBatchAllot.prototype, "caseDistributeService", void 0);
    __decorate([
        Dependencies(DistributeCaseService)
    ], AdjustmentCaseBatchAllot.prototype, "distributeCaseService", void 0);
    __decorate([
        State
    ], AdjustmentCaseBatchAllot.prototype, "userData", void 0);
    __decorate([
        State
    ], AdjustmentCaseBatchAllot.prototype, "principalList", void 0);
    __decorate([
        Prop({ default: "PERSONAL_HAS_ISSUED" })
    ], AdjustmentCaseBatchAllot.prototype, "caseIssuedFlag", void 0);
    __decorate([
        Emit("close")
    ], AdjustmentCaseBatchAllot.prototype, "onclose", null);
    __decorate([
        Emit("success")
    ], AdjustmentCaseBatchAllot.prototype, "onSuccess", null);
    AdjustmentCaseBatchAllot = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                DepartmentTree: DepartmentTree,
                AdjustmentBatchAllocate: AdjustmentBatchAllocate
            }
        })
    ], AdjustmentCaseBatchAllot);
    return AdjustmentCaseBatchAllot;
}(Vue));
export default AdjustmentCaseBatchAllot;
