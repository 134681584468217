/**
 * 委托业务类性
 */
var Business_Type;
(function (Business_Type) {
    Business_Type["CREDIT_CARD"] = "\u4FE1\u7528\u5361";
    Business_Type["PERSONAL_LOAN"] = "\u4E2A\u8D37";
    Business_Type["CAR_LOAN"] = "\u8F66\u8D37";
    Business_Type["HOUSE_LOAN"] = "\u623F\u8D37";
    Business_Type["OTHER"] = "\u5176\u4ED6";
})(Business_Type || (Business_Type = {}));
/**
 * 机构类型
 */
var Principal_Type;
(function (Principal_Type) {
    Principal_Type["INSURANCE"] = "\u4FDD\u9669\u516C\u53F8";
    Principal_Type["BANK"] = "\u94F6\u884C";
    Principal_Type["LOAN_COMPANY"] = "\u8D37\u6B3E\u516C\u53F8";
    Principal_Type["AGENCIES"] = "\u8C03\u6536\u673A\u6784";
    Principal_Type["OTHER"] = "\u5176\u4ED6";
})(Principal_Type || (Principal_Type = {}));
/**
 * 审批状态类型
 */
var Approve_Type;
(function (Approve_Type) {
    Approve_Type["wait"] = "WAIT_APPROVAL";
    Approve_Type["complete"] = "APPROVED_COMPLETED";
})(Approve_Type || (Approve_Type = {}));
/**
 * 菜单入口通道，用于公共组件区分从哪个菜单进入
 */
var MENU_CHANNEL;
(function (MENU_CHANNEL) {
    /**
     * 个人分案
     */
    MENU_CHANNEL[MENU_CHANNEL["DISTRIBUTE_PERSONAL"] = 0] = "DISTRIBUTE_PERSONAL";
    /**
     * 地区分案
     */
    MENU_CHANNEL[MENU_CHANNEL["DISTRIBUTE_AREA"] = 1] = "DISTRIBUTE_AREA";
    /**
     * 案件状态
     */
    MENU_CHANNEL[MENU_CHANNEL["CASE_STATE"] = 2] = "CASE_STATE";
    /**
     * 个案调整
     */
    MENU_CHANNEL[MENU_CHANNEL["CASE_ADJUSTMENT"] = 3] = "CASE_ADJUSTMENT";
    /**
     * 全部案件
     */
    MENU_CHANNEL[MENU_CHANNEL["ALL_CASE"] = 4] = "ALL_CASE";
})(MENU_CHANNEL || (MENU_CHANNEL = {}));
export { 
/**
 * Business_Type
 */
Business_Type, 
/**
 * 机构类型
 */
Principal_Type, 
/**
 * 审批状态类型
 */
Approve_Type, 
/**
 * 菜单入口通道，用于公共组件区分从哪个菜单进入
 */
MENU_CHANNEL, };
