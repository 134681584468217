import { render, staticRenderFns } from "./retire-case.vue?vue&type=template&id=3b42fbe5&scoped=true"
import script from "./retire-case.vue?vue&type=script&lang=ts"
export * from "./retire-case.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b42fbe5",
  null
  
)

export default component.exports